import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import BtnCus from "./Btn/BtnCus";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dLogout } from "../store/actions";
import GantiPassword from "./ModalGantiPassword";
import { Drawer, List, ListItem, ListItemIcon } from "@mui/material";
import Logo from "../assets/logo/logo.png";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

const pagesAdmin = [
  { icon: <BookOutlinedIcon />, lab: "Materi", url: "/loged/materi" },
  // { icon: <AssignmentOutlinedIcon />, lab: "Tugas", url: "/loged/tugas" },
  // { icon: <StarBorderOutlinedIcon />, lab: "Nilai", url: "/loged/nilai" },
  // { icon: <ListAltOutlinedIcon />, lab: "Absensi", url: "/loged/absensi" },
  { icon: <SchoolOutlinedIcon />, lab: "Siswa", url: "/loged/siswa" },
  { icon: <AssignmentIndOutlinedIcon />, lab: "Guru", url: "/loged/guru" },
  { icon: <PersonOutlineOutlinedIcon />, lab: "User", url: "/loged/user" },
];
const pagesGuru = [
  { icon: <BookOutlinedIcon />, lab: "Materi", url: "/loged/materi" },
  { icon: <AssignmentOutlinedIcon />, lab: "Tugas", url: "/loged/tugas" },
  { icon: <StarBorderOutlinedIcon />, lab: "Nilai", url: "/loged/nilai" },
  // { icon: <ListAltOutlinedIcon />, lab: "Absensi", url: "/loged/absensi" },
  { icon: <SchoolOutlinedIcon />, lab: "Siswa", url: "/loged/siswa" },
];
const pagesSiswa = [
  { icon: <BookOutlinedIcon />, lab: "Materi", url: "/loged/materi" },
  { icon: <AssignmentOutlinedIcon />, lab: "Tugas", url: "/loged/tugas" },
  { icon: <StarBorderOutlinedIcon />, lab: "Nilai", url: "/loged/nilai" },
];

const ResponsiveAppBar = () => {
  const profile = useSelector((state) => state.data.profil);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const movePage = (url) => {
    navigate(`${url}`);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function gantiPassword() {
    setOpen(true);
  }

  function logout() {
    dispatch(dLogout(navigate));
  }

  function toProfil() {
    navigate("/loged/profile");
  }

  const settings = [
    { lab: "Profile", act: toProfil },
    { lab: "Ganti Password", act: gantiPassword },
    { lab: "Logout", act: logout },
  ];
  const settingsAdmin = [
    { lab: "Ganti Password", act: gantiPassword },
    { lab: "Logout", act: logout },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{ backgroundColor: "transparent" }}
    >
      <Container maxWidth="xl" sx={{ p: { xs: 0 } }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              bgcolor: "white",
              p: "6px",
              borderRadius: "6px",
            }}
          >
            <img src={Logo} alt="logo" style={{ width: "100px" }} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              PaperProps={{ className: "drawer" }}
              anchor="left"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <List sx={{ width: "150px", p: "40px 10px" }}>
                {(profile && profile.role === "super_admin"
                  ? pagesAdmin
                  : profile && profile.data_user.role === "guru"
                  ? pagesGuru
                  : pagesSiswa
                ).map((page) => (
                  <ListItem
                    sx={
                      pathname.includes(page.url)
                        ? {
                            backgroundColor: "rgba(239, 204, 255, 0.3)",
                            borderRadius: "10px",
                          }
                        : {}
                    }
                    key={page.lab}
                    onClick={() => movePage(page.url)}
                  >
                    {page.icon}
                    <Typography ml="10px">{page.lab}</Typography>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <img
              src={Logo}
              alt="logo"
              style={{
                width: "100px",
                backgroundColor: "white",
                padding: "6px",
                borderRadius: "6px",
              }}
            />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {(profile && profile.role === "super_admin"
              ? pagesAdmin
              : profile && profile.data_user.role === "guru"
              ? pagesGuru
              : pagesSiswa
            ).map((page, i) => (
              <BtnCus
                variant={pathname.includes(page.url) ? "btn-act" : null}
                text={page.lab}
                color="secondary"
                key={i}
                onClick={() => movePage(page.url)}
                icon={page.icon}
                style={{ padding: "4px 12px" }}
              />
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "flex", md: "flex" },
              alignItems: "center",
            }}
          >
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    bgcolor: "white",
                    color: "purple",
                    width: { xs: "30px" },
                    height: { xs: "30px" },
                  }}
                  src={profile && profile.foto}
                />
              </IconButton>
            </Tooltip>
            <Typography sx={{ ml: "10px", textTransform: "capitalize" }}>
              {profile && (profile.nama ? profile.nama : profile.nama_lengkap)}
            </Typography>
            <Menu
              sx={{ mt: "35px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {(profile && profile.role === "super_admin"
                ? settingsAdmin
                : settings
              ).map((setting) => (
                <MenuItem key={setting} onClick={setting.act}>
                  <Typography textAlign="center">{setting.lab}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
        <GantiPassword
          id={profile && profile.data_user && profile.data_user.id}
          open={open}
          handleClose={handleClose}
        />
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
