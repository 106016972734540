import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import AppBarInput from "../../components/AppBarInput";
import Dropzone from "react-dropzone";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BtnCus from "../../components/Btn/BtnCus";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { getUser, importUser } from "../../store/actions";

export default function TambahUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  function updateData() {
    dispatch(getUser());
  }

  function submit(event) {
    event.preventDefault();
    let temp = new FormData();
    temp.append("file", file);
    dispatch(importUser(temp, navigate, updateData));
  }

  return (
    <div>
      <AppBarInput title="Import user" />
      <Container maxWidth="lg" sx={{ mt: "20px" }}>
        <Paper>
          <div style={{ padding: "20px" }}>
            <span>Import User</span>
          </div>
          <Divider />
          <div style={{ padding: "20px" }}>
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={submit}
            >
              
              <span className="mt14">Upload User</span>
              <div className="dropzone-div" style={{ marginTop: "10px" }}>
                <Dropzone onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone-div-section">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!file ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <p>
                              Upload file data Siswa dalam format excel sesuai
                              template
                            </p>
                            <BtnCus
                              color="secondary"
                              variant="outlined"
                              size="small"
                              text="Upload File"
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div className="p20 div-img-input">
                              <UploadFileOutlinedIcon
                                sx={{ fontSize: "100px", color: "purple" }}
                              />
                              <p>{file.name}</p>
                            </div>
                            <BtnCus
                              color="secondary"
                              onClick={() => setFile(null)}
                              variant="outlined"
                              text="Ganti"
                            />
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div style={{ marginTop: "12px" }}>
                <BtnCus
                  variant="contained"
                  type="submit"
                  color="secondary"
                  text="Submit"
                />
                <BtnCus
                  variant="outlined"
                  color="secondary"
                  text="Batal"
                  style={{ marginLeft: "12px" }}
                  onClick={() => navigate(-1)}
                />
              </div>
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
}
