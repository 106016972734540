import React, { useEffect, useState } from "react";
import AppBarKelas from "../../components/AppBarKelas";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import SearchInput from "../../components/Input/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import CardTugas from "../../components/CardTugas";
import { deleteTugas, getTugas } from "../../store/actions";
import empty from "../../assets/icon/empty.svg";
import Paper from "@mui/material/Paper";

export default function Materi() {
  const profil = useSelector((state) => state.data.profil);
  const tugass = useSelector((state) => state.data.tugas);
  const dispatch = useDispatch();
  const optKelas = useSelector((state) => state.data.kelas);
  const [kelas_id, setKelas_id] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);

  function hapus(id) {
    dispatch(deleteTugas(id));
  }

  useEffect(() => {
    if (!tugass) dispatch(getTugas());
    if (optKelas) setKelas_id(optKelas[0].id);
  }, [optKelas]);

  useEffect(() => {
    if (kelas_id && tugass) {
      setDataFiltered(tugass.filter((e) => e.data_kelas.id == kelas_id));
      setDataSearch(tugass.filter((e) => e.data_kelas.id == kelas_id));
    }
  }, [tugass, kelas_id]);

  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = dataFiltered.filter((item) => {
        const startsWith = item.nama_tugas
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.nama_tugas
          .toLowerCase()
          .includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(dataFiltered);
    }
  }

  // function filterGuru (el){
  //   if (profil && profil.data_user.role == 'guru'){
  //     if (el.data_guru == profil.id){
  //       return el
  //     } else{
  //       return null
  //     }
  //   } else {
  //     if (el.data_kelas.id <= profil.data_kelas.id){
  //       return el
  //     } else{
  //       return null
  //     }
  //   }
  // }

  return (
    <>
      <Container maxWidth="xl">
        <div style={{ width: "100%", position: "relative" }}>
          <AppBarKelas
            textAdd="Buat Tugas"
            url="/loged/tugas/buat-tugas"
            kelas_id={kelas_id}
            setKelas_id={setKelas_id}
            optKelas={optKelas}
          />
        </div>
        <div style={{ paddingTop: "50px" }}>
          <SearchInput value={searchValue} onChange={handleSearch} />
          <Grid container spacing={{ xs: 2, md: 4 }} pt="20px">
            {tugass && dataSearch.length === 0 ? (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Paper sx={{ width: "100%", textAlign: "center", p: "20px" }}>
                  <img src={empty} style={{ width: "300px" }} />
                  <div>Data Kosong</div>
                </Paper>
              </Grid>
            ) : (
              dataSearch
                // .filter((e) => filterGuru(e) )
                .filter((e) => e.data_kelas.id == kelas_id)
                .map((tugas, i) => (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CardTugas
                      key={i}
                      profil={profil}
                      data={tugas}
                      hapus={hapus}
                      hiddenBtn={
                        profil &&
                        profil.data_user &&
                        profil.data_user.role === "siswa"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                ))
            )}
          </Grid>
        </div>
      </Container>
    </>
  );
}
