import React, { useState } from "react";
import Card from "@mui/material/Card";
import BtnCus from "./Btn/BtnCus";
import { useNavigate } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

export default function AppBarKelas(props) {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.data.profil);

  // console.log(profile, 'aaaaaaa')
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div className="div-appbarclass">
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "14px",
            borderRadius: "12px",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {props.optKelas && (
              <>
                <TextField
                  sx={{ display: { xs: "block", md: "none" } }}
                  select
                  value={props.kelas_id}
                  onChange={(e) => props.setKelas_id(e.target.value)}
                  size="small"
                >
                  {props.optKelas &&
                    props.optKelas.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        Kelas {option.nama_kelas}
                      </MenuItem>
                    ))}
                </TextField>
                {props.optKelas.map((eClass, i) => (
                  <Box
                    key={i}
                    sx={{ display: { xs: "none", md: "block" }, mr: "12px" }}
                  >
                    <BtnCus
                      onClick={() => props.setKelas_id(eClass.id)}
                      variant={
                        eClass.id === props.kelas_id ? "contained" : "outlined"
                      }
                      color="secondary"
                      text={`Kelas ${eClass.nama_kelas}`}
                    />
                  </Box>
                ))}
              </>
            )}
          </div>
          {!props.hiddenBtn &&
            profile &&
            (profile.role === "super_admin" ||
              profile.data_user.role === "guru") && (
              <BtnCus
                variant="contained"
                color="secondary"
                text={props.textAdd}
                onClick={() => navigate(props.url)}
              />
            )}
        </Card>
      </div>
    </div>
  );
}
