import React, { useState } from "react";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { gantiPassword } from "../store/actions";
import Swal from "sweetalert2";

export default function GantiPassword(props) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [input, setInput] = useState({ password: "", ulangiPassword: "" });

  function onChangeInput(event) {
    let { name, value } = event.target;
    setInput({
      ...input,
      [name]: value,
    });
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function submit(event) {
    event.preventDefault();
    if (input.password === input.ulangiPassword) {
      props.handleClose();
      dispatch(gantiPassword(props.id, input.password));
    } else {
      Swal.fire({ icon: "error", title: "Password Belum Sesuai" });
    }
  }

  console.log(props.id)
  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Ganti Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Silahkan masukkan password baru anda.
          </DialogContentText>
          <div style={{ marginTop: "16px" }}>
            <TextField
              size="small"
              label="Password Baru"
              fullWidth
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              autoComplete="current-password"
              value={input.password}
              onChange={onChangeInput}
              InputProps={{
                autocomplete: "new-password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility className="placeholder" />
                      ) : (
                        <VisibilityOff className="placeholder" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ marginTop: "16px" }}>
            <TextField
              size="small"
              label="Ulangi Password"
              fullWidth
              type={showPassword ? "text" : "password"}
              id="password"
              error={input.password !== input.ulangiPassword}
              helperText={
                input.ulangiPassword && input.password !== input.ulangiPassword
                  ? "Password tidak sesuai"
                  : null
              }
              autoComplete="current-password"
              name="ulangiPassword"
              value={input.ulangiPassword}
              onChange={onChangeInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility className="placeholder" />
                      ) : (
                        <VisibilityOff className="placeholder" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit}>Submit</Button>
          <Button onClick={props.handleClose}>Batal</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
