import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import React, { useState } from "react";
import CardMedia from "@mui/material/CardMedia";
import BtnCus from "./Btn/BtnCus";
import Typography from "@mui/material/Typography";
import BtnSetting from "./Btn/BtnSetting";
import { showDateInd } from "../helper/convertdate";
import ModalMateri from "./ModalMateri";
import bg from "../assets/images/bg-m.png";
import Chip from "@mui/material/Chip";

export default function CardMateri(props) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Card sx={{ width: "100%", borderRadius: "12px" }}>
        <CardContent sx={{ position: "relative" }}>
          <CardMedia component="img" height="120" image={bg} />
          <div className="div-card-title">
            <div>
              <div className="title-card-materi">{props.data.title}</div>
              <Chip
                sx={{ mt: "8px" }}
                size="small"
                label={props.data.kategori ? props.data.kategori : "-"}
                color="info"
              />
            </div>
            {!props.hiddenBtn && (
              <BtnSetting hapus={props.hapus} data={props.data} />
            )}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "12px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="caption">
                {" "}
                {showDateInd(props.data.created_at)}
              </Typography>
              <Typography variant="caption">
                {props.data.uploaded_by}
              </Typography>
            </div>
            <div>
              <BtnCus
                size="small"
                variant="contained"
                color="secondary"
                text="Lihat"
                onClick={() => setOpen(true)}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      <ModalMateri
        open={open}
        optKelas={props.optKelas}
        handleClose={handleClose}
        data={props.data}
      />
    </>
  );
}
