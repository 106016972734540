import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import SearchInput from "../../components/Input/SearchInput";
import PaginationCus from "../../components/PaginationCus";
import BtnCus from "../../components/Btn/BtnCus";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { deleteUser, getUser } from "../../store/actions";
import GantiPassword from "../../components/ModalGantiPassword";

export default function User() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.user);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [idCr, setIdCr] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setDataSearch(data);
  }, [data]);

  useEffect(() => {
    if (!data) dispatch(getUser());
  }, []);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setIdCr(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  function hapus() {
    dispatch(deleteUser(idCr));
  }

  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = (data && data).filter((item) => {
        const startsWith = item.nama
          .toLowerCase()
          .startsWith(value.toLowerCase());
        item.nis.toString().toLowerCase().startsWith(value.toLowerCase());
        const includes =
          item.nama.toLowerCase().includes(value.toLowerCase()) ||
          item.nis.toString().toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(data);
    }
  }

  console.log(data, 'dd')
  return (
    <>
      <Container maxWidth="lg">
        <div style={{ paddingTop: "50px" }}>
          <Box display="flex" justifyContent="space-between">
            <SearchInput value={searchValue} onChange={handleSearch} />
            <BtnCus
              text="Import User"
              variant="contained"
              onClick={() => navigate("/loged/user/import-user")}
            />
          </Box>
          <TableContainer
            component={Paper}
            sx={{ mt: "20px", borderRadius: "20px" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ bgcolor: "#f2edf5" }}>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Nama</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell align="center">Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSearch &&
                  dataSearch.map((el, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {i + 1}
                      </TableCell>
                      <TableCell>{el.nis}</TableCell>
                      <TableCell>{el.nama}</TableCell>
                      <TableCell>{el.role}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={(event) => handleClick(event, el.id)}
                        >
                          <MoreHorizSharpIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                <Menu
                  elevation={2}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <MenuItem className="menu-item-setting">
                    <DeleteOutlineOutlinedIcon
                      sx={{ mr: "10px" }}
                      onClick={hapus}
                    />{" "}
                    Hapus
                  </MenuItem>
                  <Divider />
                  <MenuItem className="menu-item-setting">
                    <LockResetOutlinedIcon
                      sx={{ mr: "10px" }}
                      onClick={handleOpen}
                    />{" "}
                    Ganti Password
                  </MenuItem>
                </Menu>
                <GantiPassword
                  id={idCr}
                  open={open}
                  handleClose={handleClose1}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationCus />
        </div>
      </Container>
    </>
  );
}
