import Box from "@mui/system/Box";
import React from "react";
import notF from "../assets/icon/404.svg";
export default function NotFound() {
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" height='100vh'>
        <img src={notF} className='img-404'/>
      </Box>
    </>
  );
}
