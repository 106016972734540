import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import SearchInput from "../../components/Input/SearchInput";
import PaginationCus from "../../components/PaginationCus";
import BtnCus from "../../components/Btn/BtnCus";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  deleteSiswa,
  getSiswa,
  naikKelasSiswa,
  setProfil,
} from "../../store/actions";
import { Checkbox, Select } from "@mui/material";

export default function Siswa() {
  const dispatch = useDispatch();
  const profil = useSelector((state) => state.data.profil);
  const optSekolah = useSelector((state) => state.data.sekolah);
  const optKelas = useSelector((state) => state.data.kelas);
  const data = useSelector((state) => state.data.siswa);
  const navigate = useNavigate();
  const [school, setSchool] = useState(null);
  const [classes, setClasses] = useState(null);
  const [kelas, setKelas] = useState(classes && classes[0] && classes[0].id);
  const [anchorEl, setAnchorEl] = useState(null);
  const [idCr, setIdCr] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [siswaChecked, setSiswaChecked] = useState([]);

  const handleItemChecked = (e, id) => {
    const { checked } = e.target;
    setSiswaChecked([...siswaChecked, id]);
    if (!checked) {
      setSiswaChecked(siswaChecked.filter((x) => x !== id));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (!data) dispatch(getSiswa());
    if (profil && profil.role !== "super_admin")
      setSchool(profil.data_sekolah.id);
  }, [profil]);

  useEffect(() => {
    if (optKelas)
      setClasses(optKelas.filter((kel) => kel.data_sekolah.id == school));
  }, [school]);

  useEffect(() => {
    if (classes && classes[0]) setKelas(classes[0].id);
  }, [classes]);

  useEffect(() => {
    setDataSearch(data);
  }, [data]);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setIdCr(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function hapus() {
    dispatch(deleteSiswa(idCr));
  }

  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = data.filter((item) => {
        const startsWith =
          item.nama_lengkap.toLowerCase().startsWith(value.toLowerCase()) ||
          item.nis.toLowerCase().startsWith(value.toLowerCase());
        const includes =
          item.nama_lengkap.toLowerCase().includes(value.toLowerCase()) ||
          item.nis.toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(data);
    }
  }

  function updateData() {
    dispatch(getSiswa());
  }

  function handleNaikKelas() {
    dispatch(naikKelasSiswa(siswaChecked, kelas + 1, updateData));
  }

  useEffect(() => {
    setSiswaChecked([]);
    setPage(1)
  }, [kelas]);

  console.log(kelas, siswaChecked, "aaa");
  return (
    <>
      <Container maxWidth="lg">
        <div style={{ width: "100%", position: "relative" }}>
          <div style={{ width: "100%", position: "relative" }}>
            <div className="div-appbarclass">
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "14px",
                  borderRadius: "12px",
                }}
              >
                <Select
                  disabled={profil && profil.role !== "super_admin"}
                  sx={{ width: "130px" }}
                  value={school}
                  onChange={(e) => setSchool(e.target.value)}
                  size="small"
                  displayEmpty
                  renderValue={
                    school !== null
                      ? undefined
                      : () => <div value={null}>Pilih Sekolah</div>
                  }
                >
                  {optSekolah &&
                    optSekolah.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nama_sekolah}
                      </MenuItem>
                    ))}
                </Select>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {optKelas && (
                    <>
                      <Select
                        sx={{ display: { xs: "block", md: "none" } }}
                        displayEmpty
                        renderValue={
                          school !== null
                            ? undefined
                            : () => <div value={null}>Pilih Kelas</div>
                        }
                        value={kelas}
                        onChange={(e) => setKelas(e.target.value)}
                        size="small"
                      >
                        {optKelas &&
                          optKelas
                            .filter((e) => e.data_sekolah.id === school)
                            .map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                Kelas {option.nama_kelas}
                              </MenuItem>
                            ))}
                      </Select>
                      {optKelas
                        .filter((e) => e.data_sekolah.id === school)
                        .map((eClass, i) => (
                          <Box
                            key={i}
                            sx={{
                              display: { xs: "none", md: "block" },
                              mr: "12px",
                            }}
                          >
                            <BtnCus
                              onClick={() => setKelas(eClass.id)}
                              variant={
                                eClass.id === kelas ? "contained" : "outlined"
                              }
                              color="secondary"
                              text={`Kelas ${eClass.nama_kelas}`}
                            />
                          </Box>
                        ))}
                    </>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "50px" }}>
          <Box display="flex" justifyContent="space-between">
            <SearchInput value={searchValue} onChange={handleSearch} />
            <Box>
              <BtnCus
                onClick={handleNaikKelas}
                disabled={siswaChecked.length === 0}
                style={{
                  marginRight: "10px",
                }}
                variant="outlined"
                text="Naik Kelas"
              />
              <BtnCus
                text="Import Data Siswa"
                variant="contained"
                onClick={() => navigate("/loged/siswa/import-data-siswa")}
              />
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ mt: "20px", borderRadius: "20px" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ bgcolor: "#f2edf5" }}>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Nama Lengkap</TableCell>
                  <TableCell>NIS</TableCell>
                  <TableCell>Jenis Kelamin</TableCell>
                  <TableCell>Tanggal Lahir</TableCell>
                  <TableCell align="center">Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSearch &&
                  (rowsPerPage > 0
                    ? dataSearch
                        .filter((e) => e.data_sekolah.id === school)
                        .filter((e) => e.data_kelas.id === kelas)
                        .slice(
                          (page - 1) * rowsPerPage,
                          (page - 1) * rowsPerPage + rowsPerPage
                        )
                    : dataSearch
                        .filter((e) => e.data_sekolah.id === school)
                        .filter((e) => e.data_kelas.id === kelas)
                  ).map((el, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Checkbox
                          checked={siswaChecked.includes(el.id)}
                          value={el.id}
                          onChange={(e) => handleItemChecked(e, el.id)}
                          sx={{ mr: "10px" }}
                        />
                        {rowsPerPage > 0
                          ? (page - 1) * rowsPerPage + i + 1
                          : i + 1}
                      </TableCell>
                      <TableCell>{el.nama_lengkap}</TableCell>
                      <TableCell>{el.nis}</TableCell>
                      <TableCell>{el.jenis_kelamin}</TableCell>
                      <TableCell>{el.tanggal_lahir}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={(event) => handleClick(event, el.id)}
                        >
                          <MoreHorizSharpIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                <Menu
                  elevation={2}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <MenuItem
                    className="menu-item-setting"
                    onClick={() => navigate(`/loged/siswa/detail/${idCr}`)}
                  >
                    <InfoOutlinedIcon sx={{ mr: "10px" }} /> Detail
                  </MenuItem>
                  <Divider
                    sx={{
                      marginTop: "2px !important",
                      marginBottom: "2px !important",
                    }}
                  />
                  <MenuItem className="menu-item-setting">
                    <DeleteOutlineOutlinedIcon
                      sx={{ mr: "10px" }}
                      onClick={hapus}
                    />{" "}
                    Hapus
                  </MenuItem>
                </Menu>
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationCus
            handleChangePage={handleChangePage}
            page={page}
            setPage={setPage}
            dataFilter={
              dataSearch &&
              dataSearch
                .filter((e) => e.data_sekolah.id === school)
                .filter((e) => e.data_kelas.id === kelas)
            }
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </Container>
    </>
  );
}
