import React from "react";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/system/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function PaginationCustom(props) {
  const handleChangePage = (event, newPage) => {
    props.setPage({
      ...props.allPage,
      [props.name]: newPage,
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" padding="20px">
        <Box display="flex" alignItems="center">
          <div>Menampilkan</div>
          <div style={{ marginLeft: "12px" }}>
            <FormControl variant="outlined" color="secondary" size="small">
              <Select
                name={props.name}
                size="small"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                value={props.rowsPerPage}
                onChange={props.handleChangeRowsPerPage}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={0}>All</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ marginLeft: "12px" }}>
            dari {props.dataFilter?.length} Data
          </div>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Pagination
            page={props.page}
            onChange={props.handleChangePage}
            count={
              props.rowsPerPage > 0
                ? Math.ceil(props.dataFilter?.length / props.rowsPerPage)
                : 1
            }
            color="primary"
            name={props.name}
            size="small"
          />
        </Box>
      </Box>
    </>
  );
}
