import React, { useEffect, useState } from "react";
import AppBarKelas from "../../components/AppBarKelas";
import CardMateri from "../../components/CardMateri";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import SearchInput from "../../components/Input/SearchInput";
import PaginationCus from "../../components/PaginationCus";
import { useDispatch, useSelector } from "react-redux";
import { deleteMateri, getMateri } from "../../store/actions";
import empty from "../../assets/icon/empty.svg";
import Paper from "@mui/material/Paper";
import PaginationBar from "../../components/PaginationBar";

export default function Materi() {
  const profil = useSelector((state) => state.data.profil);
  const dispatch = useDispatch();
  const materis = useSelector((state) => state.data.materi);
  const optKelas = useSelector((state) => state.data.kelas);
  const [searchValue, setSearchValue] = useState("");
  const [kelas_id, setKelas_id] = useState(optKelas && optKelas[0].id);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(2);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (!materis) dispatch(getMateri());
    if (optKelas) setKelas_id(optKelas[0].id);
  }, [optKelas]);

  useEffect(() => {
    if (kelas_id && materis) {
      setDataFiltered(materis.filter((e) => e.kelas == kelas_id));
      setDataSearch(materis.filter((e) => e.kelas == kelas_id));
    }
  }, [materis, kelas_id]);

  function hapus(id) {
    dispatch(deleteMateri(id));
  }

  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = dataFiltered.filter((item) => {
        const startsWith = item.title
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.title.toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(dataFiltered);
    }
  }

  useEffect(() => {
    setPage(1);
  }, [kelas_id]);
  return (
    <>
      <Container maxWidth="xl">
        <div style={{ width: "100%", position: "relative" }}>
          <AppBarKelas
            textAdd="Buat Materi"
            url="/loged/materi/buat-materi"
            kelas_id={kelas_id}
            setKelas_id={setKelas_id}
            optKelas={optKelas}
          />
        </div>
        <div style={{ paddingTop: "50px" }}>
          <SearchInput value={searchValue} onChange={handleSearch} />
          <Grid container spacing={{ xs: 1, md: 2 }} pt="20px">
            {materis && dataSearch.length === 0 ? (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Paper sx={{ width: "100%", textAlign: "center", p: "20px" }}>
                  <img src={empty} style={{ width: "300px" }} />
                  <div>Data Kosong</div>
                </Paper>
              </Grid>
            ) : (
              (rowsPerPage > 0
                ? dataSearch.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : dataSearch
              ).map((materi, i) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CardMateri
                    optKelas={optKelas}
                    key={i}
                    data={materi}
                    hapus={hapus}
                    hiddenBtn={
                      profil &&
                      profil.data_user &&
                      profil.data_user.role === "siswa"
                        ? true
                        : false
                    }
                  />
                </Grid>
              ))
            )}
          </Grid>
        </div>
        <PaginationBar
          handleChangePage={handleChangePage}
          page={page}
          setPage={setPage}
          dataFilter={dataSearch && dataSearch}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
    </>
  );
}
