import React, { useEffect, useState } from "react";
import AppBarKelas from "../../components/AppBarKelas";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import SearchInput from "../../components/Input/SearchInput";
import BtnCus from "../../components/Btn/BtnCus";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSiswa } from "../../store/actions";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function Nilai() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.siswa);
  const optKelas = useSelector((state) => state.data.kelas);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [kelas_id, setKelas_id] = useState(optKelas && optKelas[0].id);

  useEffect(() => {
    if (!data) dispatch(getSiswa());
    if (optKelas) setKelas_id(optKelas[0].id);
  }, [optKelas]);

  useEffect(() => {
    if (kelas_id && data) {
      setDataFiltered(data.filter((e) => e.data_kelas.id == kelas_id));
      setDataSearch(data.filter((e) => e.data_kelas.id == kelas_id));
    }
  }, [data, kelas_id]);

  
  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = dataFiltered.filter((item) => {
        const startsWith = item.nama_lengkap
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.nama_lengkap.toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(dataFiltered);
    }
  }

  return (
    <>
      <Container maxWidth="md">
       <div style={{ width: "100%", position: "relative" }}>
          <AppBarKelas
            hiddenBtn = {true}
            url="/loged/materi/buat-materi"
            kelas_id={kelas_id}
            setKelas_id={setKelas_id}
            optKelas={optKelas}
          />
        </div>
        <div style={{ paddingTop: "50px" }}>
          <SearchInput value={searchValue} onChange={handleSearch}/>
          <TableContainer
            component={Paper}
            sx={{ mt: "20px", borderRadius: "20px" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ bgcolor: "#f2edf5" }}>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell align="center">Nama Siswa</TableCell>
                  <TableCell align="center">Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSearch.map((row, i) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      {i+1}
                    </TableCell>
                    <TableCell align="center">
                      {row.nama_lengkap}
                    </TableCell>
                    <TableCell align="center">
                      <BtnCus
                        text="Lihat Nilai"
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => navigate(`/loged/nilai/detail/${row.id}`)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
    </>
  );
}
