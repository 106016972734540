import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import SearchInput from "../../components/Input/SearchInput";
import BtnCus from "../../components/Btn/BtnCus";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import axios from "axios";
import { apiKey, server } from "../../config/config";
import { Box, IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { deleteNilai } from "../../store/actions";
import { showDateInd, showDayInd } from "../../helper/convertdate";

export default function DetailNilai() {
  const [searchValue, setSearchValue] = useState("");
  const profile = useSelector((state) => state.data.profil);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { siswa_id } = useParams();
  const [datas, setData] = useState(null);
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (siswa_id) update();
  }, [siswa_id]);

  function update() {
    Swal.showLoading();
    axios({
      url: `${server}nilai/view/${siswa_id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        Swal.close();
        setData(data.data);
        setDataSearch(data.data);
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({ icon: "error", title: "Server Eror" });
        console.log(err);
      });
  }

  function hapus(id) {
    dispatch(deleteNilai(id, update));
  }

  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (datas && value.length) {
      updatedData = datas.filter((item) => {
        const startsWith = item.data_mapel.nama_pelajaran
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.data_mapel.nama_pelajaran
          .toLowerCase()
          .includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(datas);
    }
  }

  return (
    <Container maxWidth="lg">
      <Button
        onClick={() => navigate(-1)}
        variant="outlined"
        color="secondary"
        sx={{ my: "20px", borderRadius: "10px" }}
        size="small"
      >
        <ArrowBackIcon fontSize="small" sx={{ mr: "6px" }} />
        Kembali
      </Button>
      <div>
        <Box display="flex" justifyContent="space-between">
          <SearchInput value={searchValue} onChange={handleSearch}/>
          {profile &&
            (profile.role === "super_admin" ||
              profile.data_user.role === "guru") && (
              <BtnCus
                text="Input Nilai"
                variant="contained"
                color="secondary"
                onClick={() => navigate(`/loged/nilai/input-nilai/${siswa_id}`)}
              />
            )}
        </Box>
        <TableContainer
          component={Paper}
          sx={{ mt: "20px", borderRadius: "20px" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ bgcolor: "#f2edf5" }}>
              <TableRow>
                <TableCell>Mata Pelajaran</TableCell>
                <TableCell align="center">Nilai Tugas</TableCell>
                <TableCell align="center">Nilai UTS</TableCell>
                <TableCell align="center">Nilai UAS</TableCell>
                <TableCell align="center">Diisi Oleh</TableCell>
                <TableCell align="center">Waktu</TableCell>
                {profile && profile.data_user.role === "guru" && (
                  <TableCell align="center">Aksi</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {datas &&
                dataSearch.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.data_mapel.nama_pelajaran}
                    </TableCell>
                    <TableCell align="center">{row.nilai_tugas}</TableCell>
                    <TableCell align="center">{row.nilai_uts}</TableCell>
                    <TableCell align="center">{row.nilai_uas}</TableCell>
                    <TableCell align="center">{row.diIsiOleh}</TableCell>
                    <TableCell align="center">{showDateInd(row.updated_at)}</TableCell>
                    {profile && profile.data_user.role === "guru" && (
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() =>
                            navigate(`/loged/nilai/edit-nilai/${siswa_id}/${row.id}`)
                          }
                        >
                          <ModeEditOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="small" onClick={() => hapus(row.id)}>
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
}
