import axios from "axios";
import Swal from "sweetalert2";
import { apiKey, server } from "../../config/config";

export function login(input, navigate) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      method: "post",
      url: `${server}login`,
      data: { nis: input.username, password: input.password },
      headers: {
        Accept: "application/json",
        "x-api-key": apiKey,
      },
    })
      .then((data) => {
        sessionStorage.setItem("token", data.data.token);
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Berhasil Login",
        });
        navigate(`/loged/materi`);
      })
      .catch(function (error) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Username atau Password Salah",
          text: "Silahkan masukkan username dan password anda dengan benar!",
        });
      });
  };
}

export function setProfil() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      method: "get",
      url: `${server}profile`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then((data) => {
        Swal.close();
        dispatch({
          type: "GETPROFILE",
          payload: data.data.data[0],
        });
      })
      .catch(function (error) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function gantiPassword(id, input) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      method: "post",
      url: `${server}user/update/${id}`,
      data: { password: input },
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then((data) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Password Berhasil Diubah",
        });
      })
      .catch(function (error) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function editProfil(id, input, navigate, update) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      method: "post",
      url: `${server}siswa/update/${id}`,
      data: input,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then((data) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Data Berhasil Diubah",
        });
        update();
        navigate(`/loged/profile`);
      })
      .catch(function (error) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function editProfilGuru(id, input, navigate, update) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      method: "post",
      url: `${server}guru/update/${id}`,
      data: input,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then((data) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Data Berhasil Diubah",
        });
        update();
        navigate(`/loged/profile`);
      })
      .catch(function (error) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function dLogout(navigate) {
  return (dispatch, getState) => {
    Swal.showLoading();
    sessionStorage.clear();
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    Swal.close();
    navigate(`/`);
  };
}

///action sekolah
export function getSekolah() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}sekolah/view`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "GETSEKOLAH",
          payload: data.data,
        });
        Swal.close();
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}
///

///action kelas
export function getKelas() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}kelas/view`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "GETKELAS",
          payload: data.data,
        });
        Swal.close();
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}
///
///action materi
export function getMateri() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}materi/view`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "GETMATERI",
          payload: data.data,
        });
        Swal.close();
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function addMateri(input, navigate) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}materi/create`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
      data: input,
    })
      .then(({ data }) => {
        dispatch({
          type: "ADDMATERI",
          payload: data.data,
        });
        Swal.close();
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function deleteMateri(id) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}materi/delete/${id}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "DELETEMATERI",
          payload: id,
        });
        Swal.close();
        Swal.fire("Sukses!", "Data Berhasil Dihapus", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

///

export function getSiswa() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}siswa/view`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "GETSISWA",
          payload: data.data,
        });
        Swal.close();
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function importSiswa(input, navigate, update) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}siswaimport`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
      data: input,
    })
      .then(({ data }) => {
        update();
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function naikKelasSiswa(id, kelas, update) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}siswa/update-kelas`,
      method: "patch",
      data: { siswa_id: id, kelas_id: kelas },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        update()
        Swal.close();
        Swal.fire("Sukses!", "Data Berhasil Diupdate", "success");
       
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function deleteSiswa(id) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}siswa/delete/${id}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "DELETESISWA",
          payload: id,
        });
        Swal.close();
        Swal.fire("Sukses!", "Data Berhasil Dihapus", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
////
///action guru
export function getGuru() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}guru/view`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "GETGURU",
          payload: data.data,
        });
        Swal.close();
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function importGuru(input, navigate, update) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}guruimport`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
      data: input,
    })
      .then(({ data }) => {
        update();
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function deleteGuru(id) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}guru/delete/${id}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "DELETEGURU",
          payload: id,
        });
        Swal.close();
        Swal.fire("Sukses!", "Data Berhasil Dihapus", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

///action tugas
export function getTugas() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}tugas/view`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "GETTUGAS",
          payload: data.data,
        });
        Swal.close();
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function addTugas(input, navigate) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}tugas/create`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
      data: input,
    })
      .then(({ data }) => {
        dispatch({
          type: "ADDTUGAS",
          payload: data.data,
        });
        Swal.close();
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function deleteTugas(id) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}tugas/delete/${id}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "DELETETUGAS",
          payload: id,
        });
        Swal.close();
        Swal.fire("Sukses!", "Data Berhasil Dihapus", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

////

///action nilai
export function getNilai() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}nilai/view`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "GETNILAI",
          payload: data.data,
        });
        Swal.close();
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function addNilai(input, navigate) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}nilai/create`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
      data: input,
    })
      .then(({ data }) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Data Berhasil Ditambahkan",
        });
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function editNilai(id, input, navigate) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}nilai/update/${id}`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
      data: input,
    })
      .then(({ data }) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Data Berhasil Diubah",
        });
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function deleteNilai(id, update) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}nilai/delete/${id}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        update();
        Swal.close();
        Swal.fire("Sukses!", "Data Berhasil Dihapus", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

/// mapel

export function getMapel() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}mapel/view`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "GETMAPEL",
          payload: data.data,
        });
        Swal.close();
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

///action user
export function getUser() {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}user/view`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "GETUSER",
          payload: data.data,
        });
        Swal.close();
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function importUser(input, navigate, update) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}userimport`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
      data: input,
    })
      .then(({ data }) => {
        update();
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  };
}

export function deleteUser(id) {
  return (dispatch, getState) => {
    Swal.showLoading();
    axios({
      url: `${server}user/delete/${id}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "DELETEUSER",
          payload: id,
        });
        Swal.close();
        Swal.fire("Sukses!", "Data Berhasil Dihapus", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
