import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import AppBarInput from "../components/AppBarInput";
import Dropzone from "react-dropzone";
import BtnCus from "../components/Btn/BtnCus";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import Select from "@mui/material/Select";
import { Avatar } from "@mui/material";
import { editProfil, setProfil } from "../store/actions";

export default function EditProfil() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profil = useSelector((state) => state.data.profil);

  useEffect(() => {
    if (profil) {
      let {
        nama_lengkap,
        jenis_kelamin,
        alamat,
        tempat_lahir,
        tanggal_lahir,
        no_hp,
        no_hp_ortu,
        kategori,
      } = profil;
      setInput({
        nama_lengkap,
        jenis_kelamin,
        alamat,
        kategori,
        tempat_lahir,
        tanggal_lahir,
        no_hp,
        no_hp_ortu,
      });
      setFile(profil.foto);
    }
  }, [profil]);

  const [input, setInput] = useState({
    nama_lengkap: "",
    nis: "",
    jenis_kelamin: "",
    alamat: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    no_hp: "",
    no_hp_ortu: "",
    kategori: "",
  });

  const [file, setFile] = useState(null);

  function changeInput(e) {
    let { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  }

  function submit(event) {
    event.preventDefault();
    let temp = {};
    // if (file && typeof file == "string") {
    //   temp = { input };
    // } else {
      temp = new FormData();
      temp.append("foto", file);
      for (let key in input) {
        temp.set(`${key}`, input[key]);
      }
    // }
    dispatch(editProfil(profil.id, temp, navigate, update));
  }

  function update() {
    dispatch(setProfil());
  }

  return (
    <div>
      <AppBarInput title="Edit Profil" />
      <Container maxWidth="lg" sx={{ mt: "20px" }}>
        <Paper>
          <div style={{ padding: "20px" }}>
            <span>Edit Profil</span>
          </div>
          <Divider />
          <div style={{ padding: "20px" }}>
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={submit}
            >
              <span className="mt14">Nama Lengkap</span>
              <TextField
                name="nama_lengkap"
                value={input.nama_lengkap}
                onChange={changeInput}
                color="secondary"
                size="small"
                placeholder="Tulis Nama Lengkap"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Jenis Kelamin</span>
              <Select
                required
                displayEmpty
                name="jenis_kelamin"
                value={input.jenis_kelamin}
                onChange={changeInput}
                color="secondary"
                size="small"
                renderValue={
                  input.jenis_kelamin !== ""
                    ? undefined
                    : () => <div value={null}>Pilih Jenis Kelamin</div>
                }
                MenuProps={{ sx: { maxHeight: "300px" } }}
                sx={{ mt: "10px" }}
              >
                <MenuItem value="Laki-Laki">Laki-Laki</MenuItem>
                <MenuItem value="Perempuan">Perempuan</MenuItem>
              </Select>
              <span className="mt14">Pilih Kategori</span>
              <Select
                required
                displayEmpty
                name="kategori"
                value={input.kategori}
                onChange={changeInput}
                color="secondary"
                size="small"
                renderValue={
                  input.kategori !== ""
                    ? undefined
                    : () => <div value={null}>Pilih Kategori</div>
                }
                MenuProps={{ sx: { maxHeight: "300px" } }}
                sx={{ mt: "10px" }}
              >
                <MenuItem value="Reguler">Reguler</MenuItem>
                <MenuItem value="ABK">ABK</MenuItem>
              </Select>
              <span className="mt14">Alamat</span>
              <TextField
                name="alamat"
                value={input.alamat}
                onChange={changeInput}
                color="secondary"
                size="small"
                placeholder="Tulis Alamat"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Tempat Lahir</span>
              <TextField
                name="tempat_lahir"
                value={input.tempat_lahir}
                onChange={changeInput}
                color="secondary"
                size="small"
                placeholder="Tulis Tempat Lahir"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Tanggal Lahir</span>
              <TextField
                name="tanggal_lahir"
                value={input.tanggal_lahir}
                onChange={changeInput}
                color="secondary"
                size="small"
                placeholder="Tulis Tanggal Lahir"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Nomor Hp</span>
              <TextField
                name="no_hp"
                value={input.no_hp}
                onChange={changeInput}
                color="secondary"
                size="small"
                placeholder="Tulis Nomor Hp"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Nomor HP Orangtua</span>
              <TextField
                name="no_hp_ortu"
                value={input.no_hp_ortu}
                onChange={changeInput}
                color="secondary"
                size="small"
                placeholder="Tulis Nomor HP Orangtua"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Upload Foto</span>
              <div className="dropzone-div" style={{ marginTop: "10px" }}>
                <Dropzone onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone-div-section">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!file ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <p>Upload foto dalam format png atau jpg</p>
                            <BtnCus
                              color="secondary"
                              variant="outlined"
                              size="small"
                              text="Upload File"
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div className="p20 div-img-input">
                              <Avatar
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  margin: "12px",
                                }}
                                src={
                                  file && typeof file == "string"
                                    ? file
                                    : URL.createObjectURL(file)
                                  // :file
                                }
                                alt="img"
                              />
                            </div>
                            <BtnCus
                              color="secondary"
                              onClick={() => setFile(null)}
                              variant="outlined"
                              text="Ganti"
                            />
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div style={{ marginTop: "12px" }}>
                <BtnCus
                  variant="contained"
                  type="submit"
                  color="secondary"
                  text="Submit"
                />
                <BtnCus
                  variant="outlined"
                  color="secondary"
                  text="Batal"
                  style={{ marginLeft: "12px" }}
                  onClick={() => navigate(-1)}
                />
              </div>
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
}
