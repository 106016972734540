import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import AppBarInput from "../../components/AppBarInput";
import Dropzone from "react-dropzone";
import BtnCus from "../../components/Btn/BtnCus";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addMateri } from "../../store/actions";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import Select from "@mui/material/Select";

export default function BuatMateri() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profil = useSelector((state) => state.data.profil);
  const optSekolah = useSelector((state) => state.data.sekolah);
  const optKelas = useSelector((state) => state.data.kelas);
  const [sekolah_id, setSekolah_id] = useState("");
  const [kelas_id, setKelas_id] = useState("");

  const [input, setInput] = useState({
    title: "",
    deskripsi: "",
    kategori: "",
    uploaded_by: profil && profil.nama_lengkap,
    guru_id: profil && profil.id,
  });

  const [file, setFile] = useState(null);

  useEffect(() => {
    if (profil) setSekolah_id(profil.data_sekolah && profil.data_sekolah.id);
  }, [profil]);

  function changeInput(e) {
    let { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  }

  function submit(event) {
    event.preventDefault();
    let temp = new FormData();
    temp.append("file", file);
    temp.set("kelas", kelas_id);
    temp.set("sekolah", sekolah_id);
    for (let key in input) {
      temp.set(`${key}`, input[key]);
    }
    dispatch(addMateri(temp, navigate));
  }

  return (
    <div>
      <AppBarInput title="Buat Materi" />
      <Container maxWidth="lg" sx={{ mt: "20px" }}>
        <Paper>
          <div style={{ padding: "20px" }}>
            <span>Buat Materi</span>
          </div>
          <Divider />
          <div style={{ padding: "20px" }}>
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={submit}
            >
              <span>Pilih Sekolah</span>
              <Select
                disabled={
                  profil && profil.data_user && profil.data_user.role === "guru"
                }
                required
                displayEmpty
                value={sekolah_id}
                onChange={(e) => setSekolah_id(e.target.value)}
                color="secondary"
                size="small"
                renderValue={
                  sekolah_id !== ""
                    ? undefined
                    : () => <div value={null}>Pilih Sekolah</div>
                }
                sx={{ my: "10px" }}
              >
                {optSekolah &&
                  optSekolah.map((e, i) => (
                    <MenuItem value={e.id} key={i}>
                      {e.nama_sekolah}
                    </MenuItem>
                  ))}
              </Select>
              <span>Pilih Kelas</span>
              <Select
                required
                displayEmpty
                value={kelas_id}
                onChange={(e) => setKelas_id(e.target.value)}
                color="secondary"
                size="small"
                renderValue={
                  kelas_id !== ""
                    ? undefined
                    : () => <div value={null}>Pilih Kelas</div>
                }
                MenuProps={{ sx: { maxHeight: "300px" } }}
                sx={{ mt: "10px" }}
              >
                {optKelas &&
                  optKelas
                    .filter((item) => item.data_sekolah.id == sekolah_id)
                    .map((e, i) => (
                      <MenuItem value={e.id} key={i}>
                        {e.nama_kelas}
                      </MenuItem>
                    ))}
              </Select>
              <span className="mt14">Pilih Kategori</span>
              <Select
                required
                displayEmpty
                name="kategori"
                value={input.kategori}
                onChange={changeInput}
                color="secondary"
                size="small"
                renderValue={
                  input.kategori !== ""
                    ? undefined
                    : () => <div value={null}>Pilih Kategori</div>
                }
                MenuProps={{ sx: { maxHeight: "300px" } }}
                sx={{ mt: "10px" }}
              >
                <MenuItem value="Reguler">Reguler</MenuItem>
                <MenuItem value="ABK">ABK</MenuItem>
              </Select>
              <span className="mt14">Judul Materi</span>
              <TextField
                name="title"
                value={input.title}
                onChange={changeInput}
                color="secondary"
                size="small"
                placeholder="Tulis Judul Materi"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Deskripsi Materi</span>
              <TextField
                name="deskripsi"
                value={input.deskripsi}
                onChange={changeInput}
                color="secondary"
                size="small"
                placeholder="Tulis Deskripsi Materi"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Upload File Materi</span>
              <div className="dropzone-div" style={{ marginTop: "10px" }}>
                <Dropzone onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone-div-section">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!file ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <p>Upload file materi dalam format Pdf atau Ppt</p>
                            <BtnCus
                              color="secondary"
                              variant="outlined"
                              size="small"
                              text="Upload File"
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div className="p20 div-img-input">
                              <UploadFileOutlinedIcon
                                sx={{ fontSize: "100px", color: "purple" }}
                              />
                              <p>{file.name}</p>
                            </div>
                            <BtnCus
                              color="secondary"
                              onClick={() => setFile(null)}
                              variant="outlined"
                              text="Ganti"
                            />
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div style={{ marginTop: "12px" }}>
                <BtnCus
                  variant="contained"
                  type="submit"
                  color="secondary"
                  text="Submit"
                />
                <BtnCus
                  variant="outlined"
                  color="secondary"
                  text="Batal"
                  style={{ marginLeft: "12px" }}
                  onClick={() => navigate(-1)}
                />
              </div>
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
}
