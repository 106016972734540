import React from "react";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/system/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function PaginationBar(props) {
  const handleChangePage = (event, newPage) => {
    props.setPage({
      ...props.allPage,
      [props.name]: newPage,
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mt='30px'>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ p: "12px", borderRadius: "12px", bgcolor: "white" }}
        >
          <Pagination
            page={props.page}
            onChange={props.handleChangePage}
            count={
              props.rowsPerPage > 0
                ? Math.ceil(props.dataFilter?.length / props.rowsPerPage)
                : 1
            }
            color="primary"
            name={props.name}
            size="small"
          />
        </Box>
      </Box>
    </>
  );
}
