import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import axios from "axios";
import { server, apiKey } from "../config/config";

export default function NilaiTugas(props) {
  const [nilai, setNilai] = useState(0);

  useEffect(() => {
    if (props.data) setNilai(props.data.nilai);
  }, [props.data]);

  function submit(event) {
    event.preventDefault();
    Swal.showLoading();
    axios({
      url: `${server}hasiltugas/update/${props.data.id}`,
      method: "patch",
      data: { nilai: nilai },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Jawaban Tugas Berhasil Diupload",
        });
        props.handleClose();
        props.update();
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({ icon: "error", title: "Server Eror" });
        console.log(err);
      });
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Beri Nilai</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", fontWeight: 600 }}>
            <Typography mr="12px">Nama Siswa</Typography>
            <Typography>
              : {props.data && props.data.data_siswa.nama_lengkap}
            </Typography>
          </div>
          <div style={{ marginTop: "16px" }}>
            <TextField
              size="small"
              label="Nilai"
              fullWidth
              id="password"
              value={nilai}
              onChange={(e) => setNilai(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit}>Submit</Button>
          <Button onClick={props.handleClose}>Batal</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
