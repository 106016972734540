import React, { useEffect, useState } from "react";
import AppBarKelas from "../../components/AppBarKelas";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import SearchInput from "../../components/Input/SearchInput";
import BtnCus from "../../components/Btn/BtnCus";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNilai, getSiswa } from "../../store/actions";
import Swal from "sweetalert2";
import axios from "axios";
import { apiKey, server } from "../../config/config";
import { showDateInd } from "../../helper/convertdate";

export default function NilaiSiswa() {
  const profil = useSelector((state) => state.data.profil);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const optKelas = useSelector((state) => state.data.kelas);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [kelas_id, setKelas_id] = useState(optKelas && optKelas[0].id);
  const [siswa_id, setSiswa_id] = useState(null);

  useEffect(() => {
    if (!data) dispatch(getSiswa());
    if (optKelas) setKelas_id(optKelas[0].id);
  }, [optKelas]);

  useEffect(() => {
    if (profil) setSiswa_id(profil.id);
  }, [profil]);

  useEffect(() => {
    if (siswa_id) update();
  }, [siswa_id]);

  function update() {
    Swal.showLoading();
    axios({
      url: `${server}nilai/view/${siswa_id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        Swal.close();
        setData(data.data);
        setDataSearch(data.data);
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({ icon: "error", title: "Server Eror" });
        console.log(err);
      });
  }

  useEffect(() => {
    if (kelas_id && data) {
      setDataFiltered(data.filter((e) => e.data_kelas.id == kelas_id));
      setDataSearch(data.filter((e) => e.data_kelas.id == kelas_id));
    }
  }, [data, kelas_id]);

  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (data && value.length) {
      updatedData = dataFiltered.filter((item) => {
        const startsWith = item.data_mapel.nama_pelajaran
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.data_mapel.nama_pelajaran
          .toLowerCase()
          .includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(dataFiltered);
    }
  }
  return (
    <>
      <Container maxWidth="md">
        <div style={{ width: "100%", position: "relative" }}>
          <AppBarKelas
            hiddenBtn={true}
            url="/loged/materi/buat-materi"
            kelas_id={kelas_id}
            setKelas_id={setKelas_id}
            optKelas={optKelas}
          />
        </div>
        <div style={{ paddingTop: "50px" }}>
          <SearchInput value={searchValue} onChange={handleSearch} />
          <TableContainer
            component={Paper}
            sx={{ mt: "20px", borderRadius: "20px" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ bgcolor: "#f2edf5" }}>
                <TableRow>
                  <TableCell>Mata Pelajaran</TableCell>
                  <TableCell align="center">Nilai Tugas</TableCell>
                  <TableCell align="center">Nilai UTS</TableCell>
                  <TableCell align="center">Nilai UAS</TableCell>
                  <TableCell align="center">Diisi Oleh</TableCell>
                  <TableCell align="center">Waktu</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  dataSearch.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.data_mapel.nama_pelajaran}
                      </TableCell>
                      <TableCell align="center">{row.nilai_tugas}</TableCell>
                      <TableCell align="center">{row.nilai_uts}</TableCell>
                      <TableCell align="center">{row.nilai_uas}</TableCell>
                      <TableCell align="center">{row.diIsiOleh}</TableCell>
                      <TableCell align="center">{showDateInd(row.updated_at)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
    </>
  );
}
