import { MenuItem, Select } from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AppBarInput from "../../components/AppBarInput";
import BtnCus from "../../components/Btn/BtnCus";
import { apiKey, server } from "../../config/config";
import { addNilai, editNilai, getMapel } from "../../store/actions";

export default function InputNilai() {
  const { id, idNilai } = useParams();
  const [siswa, setSiswa] = useState(null);
  const optMapel = useSelector((state) => state.data.mapel);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    siswa_id: null,
    guru_id: 5,
    mapel_id: null,
    kelas_id: null,
    nilai_uas: null,
    nilai_uts: null,
    nilai_tugas: null,
  });

  useEffect(() => {
    if (siswa) {
      setInput({
        ...input,
        ["siswa_id"]: siswa.id,
        ["kelas_id"]: siswa.data_kelas.id,
      });
    }
  }, [siswa]);

  function getDetail() {
    axios({
      method: "get",
      url: `${server}nilai/detail/${idNilai}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
    })
      .then((data) => {
        let n = data.data.data[0];
        if (n) {
          setInput({
            siswa_id: n.data_siswa.id,
            mapel_id: n.data_mapel.id,
            nilai_uas: n.nilai_uas,
            nilai_uts: n.nilai_uts,
            nilai_tugas: n.nilai_tugas,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  }

  useEffect(() => {
    if (!optMapel) dispatch(getMapel());
    if (idNilai) getDetail();
    axios({
      method: "get",
      url: `${server}siswa/detail/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
    })
      .then((data) => {
        setSiswa(data.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Server Eror",
        });
      });
  }, []);

  function changeInput(event) {
    let { name, value } = event.target;
    setInput({
      ...input,
      [name]: value,
    });
  }

  function submit(event) {
    event.preventDefault();
    if (idNilai) {
      dispatch(editNilai(idNilai, input, navigate));
    } else {
      dispatch(addNilai(input, navigate));
    }
  }

  return (
    <div>
      <AppBarInput title="Input Nilai" />
      <Container maxWidth="lg" sx={{ mt: "20px" }}>
        <Paper>
          <div style={{ padding: "20px" }}>
            <span>Input Nilai</span>
          </div>
          <Divider />
          <div style={{ padding: "20px" }}>
            <div className="div-out-des">
              <div className="div-des-in">
                {siswa &&
                  [
                    { lab: "Nama", val: siswa.nama_lengkap },
                    {
                      lab: "Asal Sekolah",
                      val: siswa.data_sekolah.nama_sekolah,
                    },
                    { lab: "Kelas", val: siswa.data_kelas.nama_kelas },
                  ].map((e, i) => (
                    <>
                      <div className="div-des">
                        <div style={{ width: "200px", marginRight: "16px" }}>
                          {e.lab}
                        </div>
                        <span>:</span>
                        <div style={{ marginLeft: "16px" }}>{e.val}</div>
                      </div>
                      {i !== <Divider sx={{ my: "10px" }} />}
                    </>
                  ))}
              </div>
            </div>
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={submit}
            >
              <span>Pilih Mata Pelajaran</span>
              <Select
                required
                name="mapel_id"
                displayEmpty
                value={input.mapel_id}
                onChange={changeInput}
                color="secondary"
                size="small"
                renderValue={
                  input.mapel_id !== ""
                    ? undefined
                    : () => <div value={null}>Pilih Mata Pelajaran</div>
                }
                sx={{ my: "10px" }}
              >
                {optMapel &&
                  siswa &&
                  optMapel
                    .filter(
                      (opt) => opt.data_sekolah.id === siswa.data_sekolah.id
                    )
                    .map((e, i) => (
                      <MenuItem value={e.id} key={i}>
                        {e.nama_pelajaran}
                      </MenuItem>
                    ))}
              </Select>
              <span className="mt14">Nilai Tugas</span>
              <TextField
                value={input.nilai_tugas}
                name="nilai_tugas"
                onChange={changeInput}
                color="secondary"
                size="small"
                type="number"
                placeholder="Tulis Nilai Tugas"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Nilai UTS</span>
              <TextField
                value={input.nilai_uts}
                name="nilai_uts"
                onChange={changeInput}
                color="secondary"
                size="small"
                type="number"
                placeholder="Tulis Nilai UTS"
                sx={{ mt: "10px" }}
              />
              <span className="mt14">Nilai UAS</span>
              <TextField
                value={input.nilai_uas}
                name="nilai_uas"
                onChange={changeInput}
                color="secondary"
                size="small"
                type="number"
                placeholder="Tulis Nilai UAS"
                sx={{ mt: "10px" }}
              />
              <div style={{ marginTop: "12px" }}>
                <BtnCus
                  variant="contained"
                  type="submit"
                  color="secondary"
                  text="Submit"
                />
                <BtnCus
                  variant="outlined"
                  color="secondary"
                  text="Batal"
                  onClick={() => navigate(-1)}
                  style={{ marginLeft: "12px" }}
                />
              </div>
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
}
