import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import React from "react";
import CardMedia from "@mui/material/CardMedia";
import BtnCus from "./Btn/BtnCus";
import Typography from "@mui/material/Typography";
import BtnSetting from "./Btn/BtnSetting";
import { showDateInd } from "../helper/convertdate";
import { useNavigate } from "react-router-dom";
import bg from "../assets/images/bg-m.png";
import Chip from "@mui/material/Chip";

export default function CardTugas(props) {
  const navigate = useNavigate();
  function to() {
    if (
      props.profil &&
      props.profil.data_user &&
      props.profil.data_user.role === "guru"
    ) {
      navigate(`/loged/tugas/detail/${props.data.id}`);
    } else {
      navigate(`/loged/tugas/upload-tugas/${props.data.id}`);
    }
  }
  return (
    <>
      <Card sx={{ width: "100%", borderRadius: "12px" }}>
        <CardContent sx={{ position: "relative" }}>
          <CardMedia component="img" height="120" image={bg} />
          <div className="div-card-title">
            <div>
              <div className="title-card-materi">{props.data.title}</div>
              <Chip
                sx={{ mt: "8px" }}
                size="small"
                label={props.data.kategori ? props.data.kategori : "-"}
                color="info"
              />
            </div>
            {!props.hiddenBtn && (
              <BtnSetting hapus={props.hapus} data={props.data} />
            )}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "12px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="caption">
                {" "}
                {showDateInd(props.data.created_at)}
              </Typography>
              <Typography variant="caption">
                {props.data.data_guru.nama_lengkap}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <BtnCus
                size="small"
                variant="contained"
                color="secondary"
                text="Lihat"
                onClick={to}
                style={{ height: "30px", padding: "4px 10px" }}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      {/* <ModalMateri open={open} handleClose={handleClose} data={props.data}/> */}
    </>
  );
}
