import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import React, { useState } from 'react'
import AppBarInput from '../../components/AppBarInput'
import Dropzone from 'react-dropzone';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import BtnCus from '../../components/Btn/BtnCus';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { getGuru, importGuru } from '../../store/actions'

export default function TambahGuru() {
    const optSekolah = useSelector(state => state.data.sekolah)
    const [sekolah_id, setSekolah_id] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [file, setFile] = useState(null)

    function updateData () {
        dispatch(getGuru())
    }

    function submit(event) {
        event.preventDefault()
        let temp = new FormData()
        temp.append('file', file)
        temp.set('sekolah_id', sekolah_id)
        dispatch(importGuru(temp, navigate, updateData))
    }

    return (
        <div>
            <AppBarInput title='Import data Guru' />
            <Container maxWidth='lg' sx={{ mt: '20px' }}>
                <Paper>
                    <div style={{ padding: '20px' }}>
                        <span>
                            Import data Guru
                        </span>
                    </div>
                    <Divider />
                    <div style={{ padding: '20px' }}>
                        <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={submit}>
                            <span>Pilih Sekolah</span>
                            <Select required displayEmpty name='kelas' value={sekolah_id} onChange={(e) => setSekolah_id(e.target.value)} color='secondary' size='small'
                                renderValue={
                                    sekolah_id !== '' ? undefined : () => <div value={null} >Pilih Sekolah</div>
                                }
                                sx={{ mt: '10px' }} >

                                {optSekolah && optSekolah.map((e, i) => (<MenuItem value={e.id} key={i}>{e.nama_sekolah}</MenuItem>))}
                            </Select>
                            <span className='mt14'>Upload Data Guru</span>
                            <div className='dropzone-div' style={{ marginTop: '10px' }}>
                                <Dropzone onDrop={acceptedFiles => setFile(acceptedFiles[0])}  >
                                    {({ getRootProps, getInputProps }) => (
                                        <section className='dropzone-div-section'>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {!file ?
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                        <p>Upload file data Siswa dalam format excel sesuai template</p>
                                                        <BtnCus color='secondary' variant='outlined' size='small' text='Upload File' />
                                                    </div> :
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                        <div className='p20 div-img-input'>
                                                            <UploadFileOutlinedIcon sx={{ fontSize: '100px', color: 'purple' }} />
                                                            <p>{file.name}</p>
                                                        </div>
                                                        <BtnCus color='secondary' onClick={() => setFile(null)} variant='outlined' text='Ganti' />
                                                    </div>
                                                }
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>

                            </div>
                            <div style={{ marginTop: '12px' }}>
                                <BtnCus variant='contained' type='submit' color='secondary' text='Submit' />
                                <BtnCus variant='outlined' color='secondary' text='Batal' style={{ marginLeft: '12px' }} onClick={()=> navigate(-1)}/>
                            </div>
                        </form>
                    </div>

                </Paper>
            </Container>
        </div>
    )
}
