import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { apiKey, server } from "../../config/config";
import AppBarInput from "../../components/AppBarInput";

export default function DetailGuru() {
  const { id } = useParams();
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    Swal.showLoading();
    axios({
      url: `${server}guru/detail/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        setDetail(data.data[0]);
        Swal.close();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const data = [
    { lab: "Nama Lengkap", key: "nama_lengkap" },
    { lab: "NIP", key: "nip" },
    { lab: "Jenis Kelamin", key: "jenis_kelamin" },
    { lab: "Alamat", key: "alamat" },
    { lab: "Tempat Lahir", key: "tempat_lahir" },
    { lab: "Tanggal Lahir", key: "tanggal_lahir" },
    { lab: "No Hp", key: "no_hp" },
    { lab: "Pendidikan Terakhir", key: "pendidikan_terakhir" },
  ];

  return (
    <>
      <AppBarInput title="Detail Guru" />
      <Container maxWidth="md" sx={{ py: "20px" }}>
        <Paper sx={{ borderRadius: "20px" }}>
          <div className="div-avatar-detail">
            <Avatar
              src={detail && detail.foto}
              sx={{ width: "200px", height: "200px", marginRight: "40px" }}
            />
          </div>
          <Box mt="20px">
            <Grid container spacing={2} p="20px">
              {data.map((e, i) => (
                <Grid item sm={6} key={i}>
                  <Typography fontSize="14px">{e.lab}</Typography>
                  <Typography fontSize="18px" fontWeight={600}>
                    {detail && detail[e.key]}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
