import React from 'react'
import AppBarKelas from '../../components/AppBarKelas'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container'
import SearchInput from '../../components/Input/SearchInput'
import PaginationCus from '../../components/PaginationCus';
import BtnCus from '../../components/Btn/BtnCus';
import { useNavigate } from 'react-router-dom';
import { showDayInd, showDateInd } from '../../helper/convertdate';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function Absensi() {
    const navigate = useNavigate()
    return (
        <>
            <Container maxWidth='lg'>
                <div style={{ width: '100%', position: 'relative' }}>
                    <AppBarKelas textAdd='SD Elfatih' />
                </div>
                <div style={{ paddingTop: '50px' }}>
                    <SearchInput />
                    <TableContainer component={Paper} sx={{ mt: '20px', borderRadius: '20px' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead sx={{ bgcolor: '#f2edf5' }}>
                                <TableRow>
                                    <TableCell>Tanggal</TableCell>
                                    <TableCell align="center">Hadir</TableCell>
                                    <TableCell align="center">Tidak Hadir</TableCell>
                                    <TableCell align="center">Izin</TableCell>
                                    <TableCell align="center">Sakit</TableCell>
                                    <TableCell align="center">Aksi</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {showDayInd(new Date())}, {showDateInd(new Date())}
                                        </TableCell>
                                        <TableCell align="center">{row.calories}</TableCell>
                                        <TableCell align="center">{row.fat}</TableCell>
                                        <TableCell align="center">{row.carbs}</TableCell>                                        
                                        <TableCell align="center">{row.carbs}</TableCell>
                                        <TableCell align="center"><BtnCus text='Edit' variant='outlined' size='small' color='secondary' onClick={() => navigate('/input-absensi')} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationCus />
                </div>
            </Container>
        </>
    )
}
