import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import AppBarInput from "../../components/AppBarInput";
import fileImg from "../../assets/icon/file.png";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { apiKey, server } from "../../config/config";
import Box from "@mui/system/Box";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useSelector } from "react-redux";
import { showDateInd } from "../../helper/convertdate";

const Input = styled("input")({
  display: "none",
});

export default function UploadTugas() {
  const profil = useSelector((state) => state.data.profil);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [jawaban, setJawaban] = useState(null);

  useEffect(() => {
    Swal.showLoading();
    axios({
      url: `${server}tugas/detail/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        Swal.close();
        setData(data.data[0]);
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({ icon: "error", title: "Server Error" });
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios({
      url: `${server}hasiltugas/tugas/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        let tempJawaban = null;
        if (data.data.length > 0) {
         tempJawaban = (data.data.filter((e) => e.data_siswa.id === (profil && profil.id)))[0];
        }
        Swal.close();
        setJawaban(tempJawaban);
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({ icon: "error", title: "Server Error" });
        console.log(err);
      });
  }, [profil]);

  function submit() {
    Swal.showLoading();
    let temp = new FormData();
    temp.append("file", file);
    temp.set("tugas_id", id);
    axios({
      url: `${server}hasiltugas/create`,
      method: "post",
      data: temp,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Jawaban Tugas Berhasil Diupload",
        });
        navigate(-1);
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({ icon: "error", title: "Server Eror" });
        console.log(err);
      });
  }
  return (
    <div>
      <AppBarInput title="Detail Tugas" />
      <Container maxWidth="lg" sx={{ mt: "20px" }}>
        <Paper>
          <div style={{ padding: "20px" }}>
            <span>Detail Tugas</span>
          </div>
          <Divider />
          {data && (
            <div style={{ padding: "20px" }}>
              <Typography variant="h5">{data.nama_tugas}</Typography>
              <Typography variant="paragraph">{data.deskripsi}</Typography><br />
              <Typography variant="captios">Dibuat pada: {showDateInd(data.created_at)}</Typography>
              <Grid container spacing={3} pt="20px">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography variant="h5" my="12px">
                    File Tugas
                  </Typography>
                  <img width="100px" src={fileImg} alt="file" />
                  <Button
                    sx={{
                      mt: "12px",
                      textTransform: "capitalize",
                      fontSize: "20px",
                      borderRadius: "14px",
                    }}
                    variant="contained"
                    color="info"
                  >
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      target="_blank"
                      href={data.file}
                      download
                    >
                      Download Tugas
                    </a>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Typography variant="h5" my="12px">
                    File Jawaban Tugas
                  </Typography>
                  {!jawaban || jawaban.length === 0 ? (
                    <>
                      {file ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <UploadFileOutlinedIcon
                            sx={{ fontSize: "100px", color: "green" }}
                          />
                          <Typography sx={{ mb: "12px" }}>
                            {file.name}
                          </Typography>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={submit}
                          >
                            Submit
                          </Button>
                        </Box>
                      ) : (
                        <>
                          <UploadFileOutlinedIcon
                            sx={{ fontSize: "100px", color: "green" }}
                          />
                          <label htmlFor="contained-button-file">
                            <Input
                              id="contained-button-file"
                              onChange={(e) => setFile(e.target.files[0])}
                              type="file"
                            />
                            <Button
                              component="span"
                              sx={{
                                textTransform: "capitalize",
                                fontSize: "20px",
                                borderRadius: "14px",
                                height: "50px",
                              }}
                              variant="contained"
                              color="success"
                            >
                              Unggah Jawaban Tugas
                            </Button>
                          </label>
                        </>
                      )}
                    </>
                  ) : (
                    <Box textAlign="center">
                      <UploadFileOutlinedIcon
                        sx={{ fontSize: "100px", color: "blue" }}
                      />
                      <Typography sx={{ mb: "12px" }}>
                        {jawaban.file_name}
                      </Typography>
                      <Typography sx={{ mb: "12px" }}>
                        Nilai : {jawaban.nilai}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </Paper>
      </Container>
    </div>
  );
}
