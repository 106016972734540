import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Layout from "../components/Layout";
import {
  getGuru,
  getKelas,
  getMateri,
  getSekolah,
  getSiswa,
  getTugas,
  setProfil,
} from "../store/actions";

export default function Index() {
  const dispatch = useDispatch();
  const profil = useSelector((state) => state.data.profil);
  const kelas = useSelector((state) => state.data.kelas);
  const sekolah = useSelector((state) => state.data.sekolah);
  useEffect(() => {
    if (!profil) dispatch(setProfil());
    if (!kelas) dispatch(getKelas());
    if (!sekolah) dispatch(getSekolah());
  }, []);
  
  return (
    <div>
      <Layout />
      <Outlet />
    </div>
  );
}
