import React, { useEffect, useState } from "react";
import AppBarKelas from "../../components/AppBarKelas";
import CardMateri from "../../components/CardMateri";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import SearchInput from "../../components/Input/SearchInput";
import PaginationCus from "../../components/PaginationCus";
import { useDispatch, useSelector } from "react-redux";
import { deleteMateri, getMateri } from "../../store/actions";
import empty from "../../assets/icon/empty.svg";
import { Paper } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import BtnCus from "../../components/Btn/BtnCus";
import { useNavigate } from "react-router-dom";
import PaginationBar from "../../components/PaginationBar";

export default function MateriAdmin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profil = useSelector((state) => state.data.profil);
  const optSekolah = useSelector((state) => state.data.sekolah);
  const materis = useSelector((state) => state.data.materi);
  const optKelas = useSelector((state) => state.data.kelas);
  const [searchValue, setSearchValue] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);
  const [school, setSchool] = useState(null);
  const [classes, setClasses] = useState(null);
  const [kelas, setKelas] = useState(classes && classes[0] && classes[0].id);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(2);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (!materis) dispatch(getMateri());
    if (optKelas) setKelas(optKelas[0].id);
  }, [optKelas]);

  useEffect(() => {
    if (optKelas)
      setClasses(optKelas.filter((kel) => kel.data_sekolah.id == school));
  }, [school]);

  useEffect(() => {
    if (classes && classes[0]) setKelas(classes[0].id);
  }, [classes]);

  useEffect(() => {
    if (kelas && materis) {
      setDataFiltered(materis.filter((e) => e.kelas == kelas));
      setDataSearch(materis.filter((e) => e.kelas == kelas));
    }
  }, [materis, kelas]);

  function hapus(id) {
    dispatch(deleteMateri(id));
  }

  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = dataFiltered.filter((item) => {
        const startsWith = item.title
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.title.toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(dataFiltered);
    }
  }

  useEffect(() => {
    setPage(1);
  }, [kelas]);

  return (
    <>
      <Container maxWidth="xl">
        <div style={{ width: "100%", position: "relative" }}>
          <div style={{ width: "100%", position: "relative" }}>
            <div className="div-appbarclass">
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "14px",
                  borderRadius: "12px",
                }}
              >
                <Select
                  disabled={profil && profil.role !== "super_admin"}
                  sx={{ width: "130px" }}
                  value={school}
                  onChange={(e) => setSchool(e.target.value)}
                  size="small"
                  displayEmpty
                  renderValue={
                    school !== null
                      ? undefined
                      : () => <div value={null}>Pilih Sekolah</div>
                  }
                >
                  {optSekolah &&
                    optSekolah.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nama_sekolah}
                      </MenuItem>
                    ))}
                </Select>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {optKelas && (
                    <>
                      <Select
                        sx={{ display: { xs: "block", md: "none" } }}
                        displayEmpty
                        renderValue={
                          school !== null
                            ? undefined
                            : () => <div value={null}>Pilih Kelas</div>
                        }
                        value={kelas}
                        onChange={(e) => setKelas(e.target.value)}
                        size="small"
                      >
                        {optKelas &&
                          optKelas
                            .filter((e) => e.data_sekolah.id === school)
                            .map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                Kelas {option.nama_kelas}
                              </MenuItem>
                            ))}
                      </Select>
                      {optKelas
                        .filter((e) => e.data_sekolah.id === school)
                        .map((eClass, i) => (
                          <Box
                            key={i}
                            sx={{
                              display: { xs: "none", md: "block" },
                              mr: "12px",
                            }}
                          >
                            <BtnCus
                              onClick={() => setKelas(eClass.id)}
                              variant={
                                eClass.id === kelas ? "contained" : "outlined"
                              }
                              color="secondary"
                              text={`Kelas ${eClass.nama_kelas}`}
                            />
                          </Box>
                        ))}
                    </>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "50px" }}>
          <Box display="flex" justifyContent="space-between">
            <SearchInput value={searchValue} onChange={handleSearch} />
            <BtnCus
              text="Buat Materi"
              variant="contained"
              onClick={() => navigate("/loged/materi/buat-materi")}
            />
          </Box>
          <Grid container spacing={{ xs: 1, md: 2 }} pt="20px">
            {materis && dataSearch.length === 0 ? (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Paper sx={{ width: "100%", textAlign: "center", p: "20px" }}>
                  <img src={empty} style={{ width: "300px" }} />
                  <div>Data Kosong</div>
                </Paper>
              </Grid>
            ) : (
              (rowsPerPage > 0
                ? dataSearch.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : dataSearch
              ).map((materi, i) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CardMateri key={i} data={materi} hapus={hapus} />
                </Grid>
              ))
            )}
          </Grid>
        </div>
        <PaginationBar
          handleChangePage={handleChangePage}
          page={page}
          setPage={setPage}
          dataFilter={dataSearch && dataSearch}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
    </>
  );
}
