import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import AppBarInput from "../components/AppBarInput";
import { useSelector } from "react-redux";
import BtnCus from "../components/Btn/BtnCus";
import { Button } from "@mui/material";
// import { apiKey, server } from "../../config/config";

export default function Profile() {
  const profile = useSelector((state) => state.data.profil);
  const { id } = useParams();
  const navigate = useNavigate();

  const data = [
    { lab: "Nama Lengkap", key: "nama_lengkap" },
    { lab: "NIS", key: "nis" },
    { lab: "Jenis Kelamin", key: "jenis_kelamin" },
    { lab: "Alamat", key: "alamat" },
    { lab: "Tempat Lahir", key: "tempat_lahir" },
    { lab: "Tanggal Lahir", key: "tanggal_lahir" },
    { lab: "No Hp", key: "no_hp" },
    { lab: "No Hp Orangtua", key: "no_hp_ortu" },
  ];

  return (
    <>
      <AppBarInput title="Profile" />
      <Container maxWidth="md" sx={{ py: "20px" }}>
        <Paper sx={{ borderRadius: "20px", position: "relative" }}>
          <Box
            justifyContent="flex-end"
            display="flex"
            position="absolute"
            top="10px"
            right="10px"
          >
            <Button
              style={{
                textTransform: "capitalize",
                borderRadius: "12px",
                backgroundColor: "white",
              }}
              variant="outlined"
              onClick={() => navigate("/loged/profile/edit")}
              color="secondary"
            >
              Edit Profil
            </Button>
          </Box>
          <div className="div-avatar-detail">
            <Avatar
              src={profile && profile.foto}
              sx={{ width: "200px", height: "200px", marginRight: "40px" }}
            />
          </div>
          <Box mt="20px">
            <Grid container spacing={2} p="20px">
              {profile &&
                data.map((e, i) => (
                  <Grid item sm={6} key={i}>
                    <Typography fontSize="14px">
                      {e.lab == "NIS"
                        ? profile && profile.data_user.role == "siswa"
                          ? "NIS"
                          : "NIP"
                        : e.lab}
                    </Typography>
                    <Typography fontSize="18px" fontWeight={600}>
                      {profile && e.key == "nis"
                        ? profile["nis"]
                          ? profile["nis"]
                          : profile["nip"]
                        : profile[e.key]
                        ? profile[e.key]
                        : "-"}
                    </Typography>
                  </Grid>
                ))}
              {profile && profile.data_user.role == "siswa" && (
                <Grid item sm={6}>
                  <Typography fontSize="14px">Kategori</Typography>
                  <Typography fontSize="18px" fontWeight={600}>
                    {profile.kategori}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
