import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import file from "../assets/icon/file.png";
import Stack from "@mui/material/Stack";
import { Button, Chip } from "@mui/material";

export default function ModalMateri(props) {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={props.handleClose}
      open={props.open}
    >
      <Box p="20px">
        <Box display="flex">
          <img src={file} width="60px" height='70px' />
          <div>
            <Typography fontSize="24px">{props.data.title}</Typography>
            <Typography fontSize="16px"><Chip size="small" label={props.data.kategori} color='info'/></Typography>
            <Typography>{props.data.deskripsi}</Typography>
          </div>
        </Box>
        <Box m="16px">
          <Stack spacing={1}>
            {props.optKelas && (
              <Box display="flex">
                <Typography width="150px">Kelas</Typography>
                <Typography mx="10px">:</Typography>
                <Typography>
                  {props.optKelas &&
                    props.optKelas.filter((e) => e.id == props.data.kelas)[0]
                      .nama_kelas}
                </Typography>
              </Box>
            )}
            <Box display="flex">
              <Typography width="150px">Diupload oleh</Typography>
              <Typography mx="10px">:</Typography>
              <Typography>
                {props.data.uploaded_by != "undefined"
                  ? props.data.uploaded_by
                  : "Admin"}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography width="150px">Download Materi</Typography>
              <Typography mx="10px">:</Typography>
              <Button variant="contained" size="small">
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  target="_blank"
                  href={props.data.file}
                  download
                >
                  Download
                </a>
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            onClick={props.handleClose}
            size="small"
            variant="outlined"
          >
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
