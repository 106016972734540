import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

export default function SearchInput(props) {
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 6px",
        display: "flex",
        alignItems: "center",
        width: { xs: "150px", sm: "250px" },
        borderRadius: 10,
      }}
    >
      <InputBase
        value={props.value}
        onChange={props.onChange}
        sx={{ ml: 2, flex: 1 }}
        placeholder="Cari"
        inputProps={{ "aria-label": "search google maps" }}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
