const initialState = {
  sekolah: null,
  materi: null,
  siswa: null,
  guru: null,
  tugas: null,
  profil: null,
  nilai: null,
  mapel: null,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GETPROFILE":
      return { ...state, profil: action.payload };
    case "LOGOUT":
      return {
        sekolah: null,
        materi: null,
        siswa: null,
        guru: null,
        tugas: null,
        profil: null,
        nilai: null,
        mapel: null,
        user: null,
      };
    case "GETSEKOLAH":
      return { ...state, sekolah: action.payload };
    case "GETKELAS":
      return { ...state, kelas: action.payload };
    case "GETMATERI":
      return { ...state, materi: action.payload };
    case "GETTUGAS":
      return { ...state, tugas: action.payload };
    case "GETNILAI":
      return { ...state, nilai: action.payload };
    case "GETSISWA":
      return { ...state, siswa: action.payload };
    case "GETGURU":
      return { ...state, guru: action.payload };
    case "GETMAPEL":
      return { ...state, mapel: action.payload };
    case "DELETEGURU":
      let tempGuru = state.guru.filter((e) => e.id !== action.payload);
      return { ...state, guru: tempGuru };
    case "DELETESISWA":
      let tempSiswa = state.siswa.filter((e) => e.id !== action.payload);
      return { ...state, siswa: tempSiswa };
    case "ADDMATERI":
      return { ...state, materi: [...state.materi, action.payload] };
    case "DELETEMATERI":
      let tempmateri = state.materi.filter((e) => e.id !== action.payload);
      return { ...state, materi: tempmateri };
    case "DELETETUGAS":
      let temptugas = state.tugas.filter((e) => e.id !== action.payload);
      return { ...state, tugas: temptugas };
    case "GETUSER":
      return { ...state, user: action.payload };
    case "DELETEUSER":
      let tempuser = state.user.filter((e) => e.id !== action.payload);
      return { ...state, user: tempuser };
    default:
      return state;
  }
};
