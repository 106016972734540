import { Container, Divider, Paper, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import AppBarInput from "../../components/AppBarInput";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { apiKey, server } from "../../config/config";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import BtnCus from "../../components/Btn/BtnCus";
import NilaiTugas from "../../components/ModalNilaiTugas";

export default function DetailTugas() {
  const profil = useSelector((state) => state.data.profil);
  const [open, setOpen] = useState(false);
  const [dataCr, setDataCr] = useState(null);
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    update();
  }, []);

  function update() {
    Swal.showLoading();
    axios({
      url: `${server}hasiltugas/tugas/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        "x-api-key": apiKey,
      },
    })
      .then(({ data }) => {
        Swal.close();
        setData(data.data);
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({ icon: "error", title: "Server Eror" });
        console.log(err);
      });
  }

  function beriNilai(row) {
    setOpen(true);
    setDataCr(row);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <AppBarInput title="Detail Tugas" />
      <Container maxWidth="lg" sx={{ mt: "20px" }}>
        <Paper>
          <div style={{ padding: "20px" }}>
            <span>Detail Tugas</span>
          </div>
          <Divider />
          {data && data.length > 0 && (
            <div style={{ padding: "20px" }}>
              <Typography variant="h5">
                {data[0].data_tugas.nama_tugas}
              </Typography>
              <Typography variant="paragraph">
                {" "}
                {data[0].data_tugas.deskripsi}
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ mt: "20px", borderRadius: "20px" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ bgcolor: "#f2edf5" }}>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell align="center">Nama Siswa</TableCell>
                      <TableCell align="center">Jawaban</TableCell>
                      <TableCell align="center">Nilai</TableCell>
                      <TableCell align="center">Aksi</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map((row, i) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{i + 1}</TableCell>
                          <TableCell align="center">
                            {row.data_siswa.nama_lengkap}
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="outlined" size="small">
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "purple",
                                }}
                                target="_blank"
                                href={row.file}
                                download
                              >
                                Download Jawaban
                              </a>
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row.nilai}</TableCell>
                          <TableCell align="center">
                            <BtnCus
                              text="Beri Nilai"
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={() => beriNilai(row)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </Paper>
      </Container>
      <NilaiTugas
        open={open}
        handleClose={handleClose}
        data={dataCr}
        update={update}
      />
    </div>
  );
}
