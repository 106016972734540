import Button from "@mui/material/Button";
import React, { useState } from "react";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import Divider from "@mui/material/Divider";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

export default function BtnSetting(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={() => props.hapus(props.data.id)}
        sx={{
          borderRadius: "6px",
          boxShadow: "none",
          border: "none",
          backgroundColor: "white",
          color: "purple",
          padding: "4px",
          minWidth: 0,
          "&:hover": {
            backgroundColor: "white",
            color: "purple",
          },
        }}
        variant="contained"
        size="small"
      >
        <DeleteOutlineOutlinedIcon />
      </Button>
      {/* <Menu
        className="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem className="menu-item-setting">Edit</MenuItem>
        <Divider
          sx={{ marginTop: "2px !important", marginBottom: "2px !important" }}
        />
        <MenuItem
          className="menu-item-setting"
          onClick={() => props.hapus(props.data.id)}
        >
          Hapus
        </MenuItem>
      </Menu> */}
    </>
  );
}
