import Container from '@mui/material/Container'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getKelas, getSekolah } from '../store/actions'
import ResponsiveAppBar from './AppBarRes'

export default function Layout() {
    const sekolah = useSelector(state => state.data.sekolah)
    const kelas = useSelector(state => state.data.kelas)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!sekolah) dispatch(getSekolah())
        if (!kelas) dispatch(getKelas())
    }, [])
    return (
        <div>
            <div className='div-bg-appbar'>
                <Container maxWidth='xl'>
                    <ResponsiveAppBar />                    
                </Container>
            </div>
        </div>
    )
}
