import "./App.css";
import "./assets/styles/styles.scss";
import { Routes, Route, useNavigate } from "react-router-dom";
import Index from "./pages/Index";
import BuatMateri from "./pages/Materi/BuatMateri";
import BuatTugas from "./pages/Tugas/BuatTugas";
import Materi from "./pages/Materi/Materi";
import Tugas from "./pages/Tugas/Tugas";
import Nilai from "./pages/Nilai/Nilai";
import Absensi from "./pages/Absensi/Absensi";
import InputNilai from "./pages/Nilai/InputNilai";
import UploadTugas from "./pages/Tugas/UploadTugas";
import Login from "./pages/Auth/Login";
import Siswa from "./pages/Siswa/Siswa";
import Guru from "./pages/Guru/Guru";
import TambahGuru from "./pages/Guru/TambahGuru";
import TambahSiswa from "./pages/Siswa/TambahSiswa";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProfil } from "./store/actions";
import DetailSiswa from "./pages/Siswa/Detail";
import DetailGuru from "./pages/Guru/Detail";
import Profile from "./pages/Profile";
import DetailNilai from "./pages/Nilai/DetailNilai";
import NotFound from "./components/NotFound";
import MateriAdmin from "./pages/Materi/MateriAdmin";
import NilaiSiswa from "./pages/Nilai/NilaiSiswa";
import DetailTugas from "./pages/Tugas/DetailTugas";
import User from "./pages/User/User";
import TambahUser from "./pages/User/TambahUser";
import EditProfil from "./pages/editProfil"
import EditProfilGuru from "./pages/editProfilGuru";

const pagesAdmin = [
  { el: <Profile />, url: "profile" },
  { el: <Guru />, url: "guru" },
  { el: <Siswa />, url: "siswa" },
  { el: <MateriAdmin />, url: "materi" },
  { el: <Tugas />, url: "tugas" },
  { el: <BuatTugas />, url: "tugas/buat-tugas" },
  { el: <BuatMateri />, url: "materi/buat-materi" },
  { el: <TambahGuru />, url: "guru/import-data-guru" },
  { el: <TambahSiswa />, url: "siswa/import-data-siswa" },
  { el: <DetailSiswa />, url: "siswa/detail/:id" },
  { el: <DetailGuru />, url: "guru/detail/:id" },
  { el: <InputNilai />, url: "nilai/input-nilai/:id" },
  { el: <Nilai />, url: "nilai" },
  { el: <DetailNilai />, url: "nilai/detail/:siswa_id" },
  { el: <Absensi />, url: "absensi" },
  { el: <User />, url: "user" },
  { el: <TambahUser />, url: "user/import-user" },
];

const pagesGuru = [
  { el: <Profile />, url: "profile" },
  { el: <Siswa />, url: "siswa" },
  { el: <Materi />, url: "materi" },
  { el: <Tugas />, url: "tugas" },
  { el: <BuatTugas />, url: "tugas/buat-tugas" },
  { el: <BuatMateri />, url: "materi/buat-materi" },
  { el: <TambahSiswa />, url: "siswa/import-data-siswa" },
  { el: <DetailSiswa />, url: "siswa/detail/:id" },
  { el: <DetailGuru />, url: "guru/detail/:id" },
  { el: <InputNilai />, url: "nilai/input-nilai/:id" },
  { el: <Nilai />, url: "nilai" },
  { el: <DetailNilai />, url: "nilai/detail/:siswa_id" },
  { el: <Absensi />, url: "absensi" },
  { el: <DetailTugas />, url: "tugas/detail/:id" },
  {el : <InputNilai/>, url:'nilai/edit-nilai/:id/:idNilai'},
  { el: <EditProfilGuru />, url: "profile/edit" },
];

const pagesSiswa = [
  { el: <Profile />, url: "profile" },
  { el: <Materi />, url: "materi" },
  { el: <Tugas />, url: "tugas" },
  { el: <UploadTugas />, url: "tugas/upload-tugas/:id" },
  { el: <DetailSiswa />, url: "siswa/detail/:id" },
  { el: <DetailGuru />, url: "guru/detail/:id" },
  { el: <NilaiSiswa />, url: "nilai" },
  { el: <DetailNilai />, url: "nilai/detail/:siswa_id" },
  { el: <EditProfil />, url: "profile/edit" },
];

function App() {
  const profil = useSelector((state) => state.data.profil);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.token) {
      navigate("/");
    } else {
      if (!profil) dispatch(setProfil());
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/loged/" element={<Index />}>
          {(profil && profil.role === "super_admin"
            ? pagesAdmin
            : profil && profil.data_user.role === "guru"
            ? pagesGuru
            : pagesSiswa
          ).map((page) => (
            <Route key={page.url} path={page.url} element={page.el} />
          ))}
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
